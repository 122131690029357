<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pt-3">
        <b-row v-if="dataTable.visible">
          <b-col>
            <php-lists-table ref="php-lists" />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import PhpListsTable from "@/views/RelationshipAdministrative/PhpListsTable";

export default {
  name: "PhpLists",
  components: {
    PhpListsTable
  },
  data: function() {
    return {
      dataTable: {
        visible: true
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getData() {
      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      this.$refs["php-lists"].getData();
    }
  },
  watch: {}
};
</script>

<style></style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-container', {
    staticClass: "pt-3",
    attrs: {
      "fluid": ""
    }
  }, [_vm.dataTable.visible ? _c('b-row', [_c('b-col', [_c('php-lists-table', {
    ref: "php-lists"
  })], 1)], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }